import { makeRequest } from "../axios.js";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FOLDER_MEDIA_PATH, convertDate } from '../utils.js';
import { AuthContext } from '../context/authContext.js';
import UserCart from './UserCart.jsx';
import UserWishlist from "./UserWishlist.jsx";

const UserContent = ({data,onChange,setLoginClicked}) => {
    const formatDateWithTime = "dd-MM-yyyy H:mm";
    const formatDate = "dd-MM-yyyy";
    const {currentUser} = useContext(AuthContext);
    const [isActive,setIsActive] = useState(false);
    const [productCode,setProductCode] = useState('');
    const productRef = useRef(null);
    const [productLabelActive,setProductLabelActive] = useState('');
    const [productFilled,setProductFilled] = useState('');
    const [err,setErr] = useState({});
    const [tab,setTab] = useState(false);
    const [userProducts,setUserProducts] = useState([]);
    const [userOrderStatus,setUserOrderStatus] = useState({
        unpaid:[],
        onprogress:[],
        sent:[],
        finished:[],
    });
    const [userGuaranteeClaim,setUserGuaranteeClaim] = useState([]);
    //user notification
    const [userNotificationOrders,setUserNotificationOrders] = useState([]);
    const [userNotificationGuarantees,setUserNotificationGuarantees] = useState([]);
    const [userNotificationNews,setUserNotificationNews] = useState([]);
    const [userNotificationPromos,setUserNotificationPromos] = useState([]);
    const [currentStateOrderStatus,setCurrentStateOrderStatus] = useState('unpaid');
    const [currentStateGuaranteeClaim,setCurrentStateGuaranteeClaim] = useState('claim');
    const [currentStateNotification,setCurrentStateNotification] = useState('orderStatus');

    const handleClickOrderStatus = (e,value) => {
        e.preventDefault();
        if(value===currentStateOrderStatus){
            setCurrentStateOrderStatus('');
        }else{
            setCurrentStateOrderStatus(value);
        }
    }
    const handleClickGuaranteeClaim = (e,value) => {
        e.preventDefault();
        if(value===currentStateGuaranteeClaim){
            setCurrentStateGuaranteeClaim('');
        }else{
            setCurrentStateGuaranteeClaim(value);
        }
    }
    const handleClickNotification = (e,value) => {
        e.preventDefault();
        if(value===currentStateNotification){
            setCurrentStateNotification('');
        }else{
            setCurrentStateNotification(value);
        }
    }

    useEffect(()=>{
        getUserProduct();
        getUserOrderStatusUnpaid();
        getUserOrderStatusOnprogress();
        getUserOrderStatusSent();
        getUserOrderStatusFinished();
        getUserGuaranteeClaim();
        // getUserWishlist();
        // getUserNotification();

        getUserNotificationOrder();
        getUserNotificationGuarantee();
        getUserNotificationNews();
        getUserNotificationPromo();
    },[]);

    const getUserProduct = async() => {
        try {
            const result = await makeRequest.get('user-products/find-by-userId?userId='+currentUser.id);
            if(result.status===200)setUserProducts(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }
    const getUserOrderStatusUnpaid = async() => {
        try {
            const result = await makeRequest.get('orders/find-by-status-and-userId?status=ON%20PROGRESS&userId='+currentUser.id);
            if(result.status===200){
                setUserOrderStatus(prevOrderStatus=> {
                    return {
                        ...prevOrderStatus,
                        unpaid:result.data.data
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getUserOrderStatusOnprogress = async() => {
        try {
            const result = await makeRequest.get('orders/find-by-status-and-userId?status=CONFIRMED&userId='+currentUser.id);
            if(result.status===200){
                setUserOrderStatus(prevOrderStatus=> {
                    return {
                        ...prevOrderStatus,
                        onprogress:result.data.data
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getUserOrderStatusSent = async() => {
        try {
            const result = await makeRequest.get('orders/find-by-status-and-userId?status=SENT&userId='+currentUser.id);
            if(result.status===200){
                setUserOrderStatus(prevOrderStatus=> {
                    return {
                        ...prevOrderStatus,
                        sent:result.data.data
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getUserOrderStatusFinished = async() => {
        try {
            const result = await makeRequest.get('orders/find-by-status-and-userId?status=COMPLETED&userId='+currentUser.id);
            if(result.status===200){
                setUserOrderStatus(prevOrderStatus=> {
                    return {
                        ...prevOrderStatus,
                        finished:result.data.data
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getUserGuaranteeClaim = async() => {
        try {
            const result = await makeRequest.get('guarantees/find-by-username?username='+currentUser.id);
            if(result.status===200)setUserGuaranteeClaim(result.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    // const getUserNotification = async() => {
    //     try {
    //         const result = await makeRequest.get('notifications/find-by-username?username='+currentUser.id);
    //         // console.log('user wishlist: '+JSON.stringify(result));
    //         if(result.status===200)setUserNotifications(result.data.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    const getUserNotificationOrder = async() => {
        try {
            const result = await makeRequest.get('notifications/find-by-userId-and-type?userId='+currentUser.id+'&type=order');
            if(result.status===200)setUserNotificationOrders(result.data.data);
        } catch (error) {
            console.log(error);
        }
    }
    const getUserNotificationGuarantee = async() => {
        try {
            const result = await makeRequest.get('notifications/find-by-userId-and-type?userId='+currentUser.id+'&type=news');
            if(result.status===200)setUserNotificationGuarantees(result.data.data);
        } catch (error) {
            console.log(error);
        }
    }
    const getUserNotificationNews = async() => {
        try {
            const result = await makeRequest.get('notifications/find-by-userId-and-type?userId='+currentUser.id+'&type=claim');
            if(result.status===200)setUserNotificationNews(result.data.data);
        } catch (error) {
            console.log(error);
        }
    }
    const getUserNotificationPromo = async() => {
        try {
            const result = await makeRequest.get('notifications/find-by-userId-and-type?userId='+currentUser.id+'&type=promo');
            if(result.status===200)setUserNotificationPromos(result.data.data);
        } catch (error) {
            console.log(error);
        }
    }
    // const onClickPaging = (e,pageValue) => {
    //     // console.log('page: '+pageValue);
    //     setQueryParam({...queryParam, page:pageValue});
    // }
    // const onClickPagingPrevOrNext = (e,type) => {
    //     if(type==='prev'){
    //         setQueryParam((prev)=>({...prev, page:queryParam.page-1}));
    //     } else if(type==='next'){
    //         setQueryParam((prev)=>({...prev, page:queryParam.page+1}));
    //     }
    //     getUserWishlist();
    // }
    const handleClickGuaranteeClaimProduct = (e,product) => {
        e.preventDefault();
        alert('klaim garansi '+product);
    }
    const onChangeTab = (e,value) => {
        e.preventDefault();
        setTab(value);
    }
    const handleClickRegisterProduct = (e) => {
        e.preventDefault();
        alert('registering product '+productCode+' guarantee!');
    }
    const handleClickDeleteProduct = (e,product) => {
        e.preventDefault();
        alert('going to remove this '+product+'!');
    }
    // const handleChangeCheckAll = (e) => {
    //     setCheckAll(!checkAll)
    //     let updatedList = userCarts.map(data=>{
    //         return {
    //             ...data,isChecked:!checkAll
    //         }
    //     });
    //     setUserCarts(updatedList);
    // }
    // const handleChangeCheckbox = (e,id) => {
    //     let updatedList = userCarts.map(data=>{
    //         if(data.id===id){
    //             return {
    //                 ...data,isChecked:e.target.checked
    //             }
    //         }
    //     });
    //     setUserCarts(updatedList);
    // }
    // const handleCounter = (e,counterType,id) => {
    //     let counterResult = 1;
    //     let updatedList = userCarts.map(data=>{
    //         if(data.id===id){
    //             if(counterType==='minus'){
    //                 counterResult = data.counter<2 ? 1 : data.counter-1;
    //             }else if('plus'){
    //                 counterResult = data.counter+1;
    //             }
    //             return {
    //                 ...data,counter:counterResult
    //             }
    //         }
    //     });
    //     setUserCarts(updatedList);
    // }
    // const openNotes = (e,id) => {
    //     let updatedList = userCarts.map(data=>{
    //         if(data.id===id){
    //             return {
    //                 ...data,notesActive:!data.notesActive
    //             }
    //         }
    //     });
    //     setUserCarts(updatedList);
    // }
    // const notesChange = (e,id) => {
    //     let updatedList = userCarts.map(data=>{
    //         if(data.id===id){
    //             return {
    //                 ...data,notes:e.target.value
    //             }
    //         }
    //     });
    //     setUserCarts(updatedList);
    // }
    const handleFocus = (e) => {
        e.preventDefault();
        productRef.current.focus();
        setProductLabelActive('active');
    }
    const handleBlur = (e) => {
        const {value} = e.target;
        productRef.current.blur();
        if(value!==''){
            setProductFilled('filled')
        } else {
            setProductLabelActive('');
            setProductFilled('');
        }
    }
  return (
    <>
    {data==='produk' && 
        <div className="content-profile">
            <div className="content-profile-tab">
                <ul className="clearfix">
                    <li className={!tab ? 'active' : ''} data-tab="list" onClick={(e)=>onChangeTab(e,false)}>Produk Saya</li>
                    <li className={tab ? 'active' : ''} data-tab="register" onClick={(e)=>onChangeTab(e,true)}>Daftar Produk</li>
                </ul>
            </div>
            <div className={!tab ? "content-profile-list active" : "content-profile-list"}>
                {userProducts.length===0 && 
                    <div className="notif-content-profile">
                        <div className="caption-notif">
                            <div className="notif-message">Produk Anda belum ada yang terdaftar!</div>
                            <div className="notif-icon">
                                <svg viewBox="0 0 1080 1080"><path className="cls-1" d="M472.11,587c2.91,30.71,8,53.3,15.26,69.23,9.45,20.29,26.77,31,50.39,31,22.65,0,40.7-11.14,50.51-31.31,8.12-16.53,13.2-38.88,15.63-68.2L623.4,362a590.27,590.27,0,0,0,3.39-63.23c0-37.19-5-65.41-15.14-86.37-8.24-17-26.77-37.13-68.44-37.13-26.65,0-48.7,9-65.41,27-16.35,17.56-24.59,41.55-24.59,71.29q0,28.34,4.12,93.33Z"/><path className="cls-1" d="M539.58,732.4c-24,0-44.7,8.42-61.41,25.13s-25,37.13-25,60.75c0,27,9.08,48.45,26.89,64,17.2,14.9,37.43,22.41,60.32,22.41,22.65,0,42.76-7.63,60-22.83,17.56-15.69,26.41-37.07,26.41-63.53,0-23.8-8.6-44.27-25.68-60.93S563.44,732.4,539.58,732.4Z"/><path className="cls-1" d="M540,1080C242.24,1080,0,837.76,0,540S242.24,0,540,0s540,242.24,540,540S837.76,1080,540,1080ZM540,79.65C286.16,79.65,79.65,286.16,79.65,540S286.16,1000.35,540,1000.35,1000.35,793.84,1000.35,540,793.84,79.65,540,79.65Z"/></svg>
                                <div className="notif-hide">
                                    Sistem tidak menyimpan data dan akses produk untuk melakukan garansi atau cek status garansi. 
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {userProducts.length>0 && userProducts.map(data=>{
                    return (
                        <div className="product-list" key={data.id}>
                            <div className="flex-row-product">
                                <div className="product-photo-wrapper">
                                    <div className="product-photo">
                                        <img src={FOLDER_MEDIA_PATH+data.product.product_colors[0].product_color_images[0].file.name} alt="productImage" />
                                    </div>
                                    <div className="product-button">
                                        {(!data.isExpired && !data.isClaimed) ? (<button onClick={(e)=>handleClickGuaranteeClaimProduct(e,data.product.name)} className="btnNine btnNine-default">Klaim Garansi</button>) 
                                        : (<button className="btnNine btnNine-default" disabled>Klaim Garansi</button>)}
                                    </div>
                                </div>
                                <div className="product-desc">
                                    <div className="product-title">{data.product.title}</div>
                                    <div className="product-code">{data.product.code}</div>
                                    <div className="product-row">
                                        <div className="product-left-row">Nama Brand</div>
                                        <div className="product-middle-row">:</div>
                                        <div>{data.product.brand.name}</div>
                                    </div>
                                    <div className="product-row">
                                        <div className="product-left-row">Nama Sub-Brand</div>
                                        <div className="product-middle-row">:</div>
                                        <div>{data.product.brand.sub_brand.name}</div>
                                    </div>
                                    <div className="product-row">
                                        <div className="product-left-row">Nama Product</div>
                                        <div className="product-middle-row">:</div>
                                        <div>{data.product.name}</div>
                                    </div>
                                    <div className="product-row">
                                        <div className="product-left-row">Kode Produk</div>
                                        <div className="product-middle-row">:</div>
                                        <div>{data.product.productCode}</div>
                                    </div>
                                    <div className="product-row">
                                        <div className="product-left-row">Tipe Produk</div>
                                        <div className="product-middle-row">:</div>
                                        <div>{data.product.type}</div>
                                    </div>
                                    <div className="product-row">
                                        <div className="product-left-row">Warna</div>
                                        <div className="product-middle-row">:</div>
                                        <div>{data.product.color}</div>
                                    </div>
                                    <div className="product-row">
                                        <div className="product-left-row">&nbsp;</div>
                                        <div className="product-middle-row">&nbsp;</div>
                                        <div>&nbsp;</div>
                                    </div>
                                    <div className="product-row">
                                        <div className="product-left-row">Garansi</div>
                                        <div className="product-middle-row">:</div>
                                        {data.isExpired ? <div className="semibold red">Kadaluarsa {convertDate(formatDate,data.expiredGuarantee)}</div>
                                        : <div className="semibold">Dalam masa garansi sampai {convertDate(formatDate,data.expiredGuarantee)}</div>}
                                    </div>
                                    <div className="pv1 clearfix row-status-garansi">
                                        <div className="pull-left">
                                            <div className="inline">
                                                <svg viewBox="0 0 1080 1080" className="icon-notif"><path className="cls-1" d="M540,1080C242.24,1080,0,837.76,0,540S242.24,0,540,0s540,242.24,540,540S837.76,1080,540,1080ZM540,79.65C286.16,79.65,79.65,286.16,79.65,540S286.16,1000.35,540,1000.35,1000.35,793.84,1000.35,540,793.84,79.65,540,79.65Z"/><path className="cls-1" d="M537.76,672.28C520,672.28,508,665,501,650c-6.57-14.42-11.25-36-13.93-64.16l-14.76-220c-2.71-42.12-4.09-73.2-4.09-92.33,0-25.86,6.92-46.41,20.57-61.06,13.91-14.92,31.72-22.17,54.43-22.17,27.22,0,45.7,9.65,54.95,28.68s13.63,45,13.63,79.81a578.28,578.28,0,0,1-3.31,61.63l0,.31L589,586.48c-2.28,27.58-6.91,48.13-14.15,62.88C567.51,664.37,554.7,672.28,537.76,672.28Z"/><path className="cls-1" d="M540.43,889.65c-19.42,0-35.94-6.13-50.5-18.75S468.21,841,468.21,818.28c0-19.58,6.92-36.46,20.58-50.16s30.84-20.72,50.79-20.72c20.13,0,36.83,6.76,51,20.67s21.17,30.32,21.17,50.21c0,22.36-7,39.49-21.4,52.35S559.57,889.65,540.43,889.65Z"/></svg>
                                            </div>
                                            {!data.isClaimed && <div className="inline caption-status">Produk Anda sedang dalam proses klaim garansi</div>}
                                            {data.isClaimed && <div className="inline caption-status">Produk Anda sudah pernah klaim garansi</div>}
                                        </div>
                                        <div className="pull-right">
                                            <svg id={data.id} name={data.name} onClick={(e)=>handleClickDeleteProduct(e,data.product.name)} viewBox="0 0 1080 1080" className="delete-claim"><path d="M268,1080c-9.24-3.11-19-5.22-27.64-9.5-36.39-18-51.56-49-54.65-88.38-10.3-131.67-21.38-263.28-32.25-394.91-5.23-63.36-10.67-126.71-15.95-190.07-.32-3.83,0-7.71,0-12.36h806c-1.87,23.49-3.65,46.8-5.58,70.11Q929.9,550.44,921.89,646q-9.17,110.27-18.31,220.53c-3.55,42.71-6.32,85.5-10.93,128.09-4.81,44.4-32.93,74-76.78,83.68a19.45,19.45,0,0,0-3.73,1.69ZM381.87,700.73q0-109.58,0-219.17c0-17-6.82-27.47-20.37-32.08-22.9-7.8-42.84,7.13-42.85,32.35q-.08,201.78,0,403.57c0,12.64-.26,25.3.12,37.93.42,14.13,8.26,24.11,21.29,28.13,22.47,6.93,41.77-7.43,41.82-31.55Q382,810.31,381.87,700.73Zm189.83-.15q0-109.58,0-219.17c0-16.91-6.89-27.4-20.51-32-22.94-7.71-42.7,7.23-42.71,32.54q-.08,212.32,0,424.64c0,6-.24,12,.15,17.91.87,13.45,8.67,23.09,21.4,27,22.5,6.85,41.63-7.53,41.67-31.73Q571.83,810.16,571.69,700.58Zm126.55-.29q0,16.34,0,32.68,0,93.3,0,186.59c0,16.75,7.08,27.29,20.82,31.76,22.67,7.38,42.37-7.28,42.39-31.93q.14-147.59,0-295.17c0-47.44-.07-94.88,0-142.32,0-13.85-5.06-24.7-17.63-31.1-21.73-11.08-45.57,4.61-45.63,30.22Q698.08,590.66,698.25,700.29Z"/><path d="M542.2,316.48q-214.55,0-429.1-.1A135.81,135.81,0,0,1,87,314c-12.56-2.49-20.7-10.65-20.89-23.17-.42-28.41-2-57.18,1.6-85.18,6-46.19,45.69-78.61,92.57-78.9q88.56-.55,177.12.11c8.4.06,12.43-2.09,14.84-10.38,5-17.17,10.9-34.08,16.8-51C383.17,25,417.45.3,460.14.15q80.13-.29,160.25,0c41.64.17,76.12,24.59,90.2,63.78q9.6,26.74,17.88,53.95c2.11,7,5.37,9,12.61,9,57.63-.34,115.27-.22,172.9-.18,60.3,0,100.52,40.16,100.63,100.31q0,26.88,0,53.77c-.09,23.16-12.66,35.71-35.94,35.72Q760.44,316.51,542.2,316.48ZM415.39,126H664.73c-5-14.8-9.4-28.84-14.63-42.55-5-13.2-15.47-19.89-29.41-19.94q-80.64-.3-161.28,0c-14,.05-24.38,6.79-29.43,19.94C424.72,97.11,420.41,111.17,415.39,126Z"/></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={tab ? "content-profile-list active" : "content-profile-list"}>
                <div className="text-center">
                    <div className="bold-caption">Masukkan kode produk Anda</div>
                    <div className="gray2 caption-desc">Pendaftaran produk yang cepat dan mudah. Dapatkan layanan dan kiat khusus terkini.</div>
                    <div className="form-group">
                        <div className="mb0">
                            <input type="text" ref={productRef} name="Kode" id="Kode" onFocus={(e)=>handleFocus(e)} onBlur={(e)=>handleBlur(e)} className={`form-control login-input ${productFilled}`} onChange={(e)=>setProductCode(e.target.value)} value={productCode} required/>
                            <label className={`${productLabelActive}`}>Kode Produk</label>
                        </div>
                    </div>
                    <button onClick={handleClickRegisterProduct} className="btnNine btnNine-default">Daftar</button>
                </div>
            </div>
        </div>
    }
    {data==='keranjang' &&
        <UserCart/>
    }
    {data==='orderStatus' &&
        <div className="content-profile">
            <div className="content-profile-list active">
                <div className="carousel-wrapper">
                    <div className={currentStateOrderStatus==="unpaid" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickOrderStatus(e,'unpaid')}>Belum bayar</div>
                        <div className={currentStateOrderStatus==="unpaid" ? "carousel-list-body active" : "carousel-list-body"}>
                            {userOrderStatus.unpaid.length===0 && <p className="gray2">Belum ada pesanan</p>}
                            {userOrderStatus.unpaid.length>0 && userOrderStatus.unpaid.map(data=>{
                                return (
                                <div className="row-notification" key={data.id}>
                                    <div className="foto-notif">
                                        <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                    </div>
                                    <div className="desc-notif">
                                        <div className="caption-notif">Pesanan belum dibayar</div>
                                        <div className="nopesanan-notif">{data.id}</div>
                                        <div className="time-notif">
                                            {convertDate(formatDateWithTime,data.createdAt)}
                                        </div>
                                    </div>
                                    <Link to="/status-belum-bayar">
                                        <div className="btn-view-notif">Lihat Selengkapnya</div>
                                    </Link>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={currentStateOrderStatus==="onprogress" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickOrderStatus(e,'onprogress')}>Diproses</div>
                        <div className={currentStateOrderStatus==="onprogress" ? "carousel-list-body active" : "carousel-list-body"}>
                            {userOrderStatus.onprogress.length===0 && <p className="gray2">Belum ada pesanan</p>}
                            {userOrderStatus.onprogress.length>0 && userOrderStatus.onprogress.map(data=>{
                                return (
                                <div className="row-notification" key={data.id}>
                                    <div className="foto-notif">
                                        <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                    </div>
                                    <div className="desc-notif">
                                        <div className="caption-notif">Pesanan sedang diproses</div>
                                        <div className="nopesanan-notif">{data.id}</div>
                                        <div className="time-notif">
                                            {convertDate(formatDateWithTime,data.createdAt)}
                                        </div>
                                    </div>
                                    <Link to="/status-diproses">
                                        <div className="btn-view-notif">Lihat Selengkapnya</div>
                                    </Link>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={currentStateOrderStatus==="sent" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickOrderStatus(e,'sent')}>Dikirim</div>
                        <div className={currentStateOrderStatus==="sent" ? "carousel-list-body active" : "carousel-list-body"}>
                            {userOrderStatus.sent.length===0 && <p className="gray2">Belum ada pesanan</p>}
                            {userOrderStatus.sent.length>0 && userOrderStatus.sent.map(data=>{
                                return (
                                <div className="row-notification" key={data.id}>
                                    <div className="foto-notif">
                                        <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                    </div>
                                    <div className="desc-notif">
                                        <div className="caption-notif">Pesanan sedang dikirim</div>
                                        <div className="nopesanan-notif">{data.id}</div>
                                        <div className="time-notif">
                                            {convertDate(formatDateWithTime,data.createdAt)}
                                        </div>
                                    </div>
                                    <Link to="/status-dikirim">
                                        <div className="btn-view-notif">Lihat Selengkapnya</div>
                                    </Link>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={currentStateOrderStatus==="finished" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickOrderStatus(e,'finished')}>Selesai</div>
                        <div className={currentStateOrderStatus==="finished" ? "carousel-list-body active" : "carousel-list-body"}>
                        {userOrderStatus.finished.length===0 && <p className="gray2">Belum ada pesanan</p>}
                        {userOrderStatus.finished.length>0 && userOrderStatus.finished.map(data=>{
                            return (
                            <div className="row-notification" key={data.id}>
                                <div className="foto-notif">
                                    <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                </div>
                                <div className="desc-notif">
                                    <div className="caption-notif">Pesanan selesai</div>
                                    <div className="nopesanan-notif">{data.id}</div>
                                    <div className="time-notif">
                                        {convertDate(formatDateWithTime,data.createdAt)}
                                    </div>
                                </div>
                                <Link to="/status-selesai">
                                    <div className="btn-view-notif">Lihat Selengkapnya</div>
                                </Link>
                            </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    {data==='klaimGaransi' && 
        <div className="content-profile">
            <div className="content-profile-list active">
                <div className="text-center">
                    <div className="bold-caption">Garansi</div>
                </div>
                <div className="carousel-wrapper">
                    <div className={currentStateGuaranteeClaim==="claim" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickGuaranteeClaim(e,'claim')}>Klaim Garansi</div>
                        <div className={currentStateGuaranteeClaim==="claim" ? "carousel-list-body active" : "carousel-list-body"}>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">Nama Produk</div>
                                <div className="right-row-garansi">: <span>NINE</span></div>
                            </div>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">Kode Produk</div>
                                <div className="right-row-garansi">: <span>SHLQ10000099993691251</span></div>
                            </div>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">Nama Toko</div>
                                <div className="right-row-garansi">
                                    <input type="text" name="nama toko" className="form-control"/>
                                </div>
                            </div>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">Alamat Toko</div>
                                <div className="right-row-garansi">
                                    <input type="text" name="Alamat toko" className="form-control"/>
                                </div>
                            </div>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">Kode Invoice</div>
                                <div className="right-row-garansi">
                                    <input type="text" name="Kode Invoice" className="form-control"/>
                                </div>
                            </div>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">Nama Customer</div>
                                <div className="right-row-garansi">: <span>Amiruddin</span></div>
                            </div>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">Email Customer</div>
                                <div className="right-row-garansi">: <span>amirgrafis@gmail.com</span></div>
                            </div>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">No. Telp Customer</div>
                                <div className="right-row-garansi">: <span>08131658118</span></div>
                            </div>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">Alamat Customer</div>
                                <div className="right-row-garansi">: <span>Kalideres RT 004/003 - Jakata Barat</span></div>
                            </div>
                            <div className="row-claim-garansi">
                                <div className="left-row-garansi">Catatan Klaim/ keluhan/rusak</div>
                                <div className="right-row-garansi">
                                    <textarea className="form-control" style={{height:'80px'}}></textarea>
                                </div>
                            </div>
                            <div className="row-claim-garansi" style={{alignItems:'flex-start'}}>
                                <div className="left-row-garansi">Upload foto&amp;video <br/>produk klaim</div>
                                <div className="right-row-garansi">
                                    <div className="wrapper-upload-foto">
                                        <input type="file" name="upload foto" id="fotoupload" className="fotoupload"/>
                                        <button type="" className="btnNine btnNine-default js-upload-photo">
                                            Silahkan Upload
                                        </button>
                                        <div className="file-name"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-claim-garansi" style={{justifyContent:'flex-end',marginTop:'20px'}}>
                                <button type="" className="btnNine btnNine-default js-upload-photo">
                                    Klaim Garansi
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={currentStateGuaranteeClaim==="history" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickGuaranteeClaim(e,'history')}>Riwayat Garansi</div>
                        <div className={currentStateGuaranteeClaim==="history" ? "carousel-list-body active" : "carousel-list-body"}>
                            <p>Klaim produk Anda sedang dalam proses pemeriksaan, mohon ditunggu!</p>
                            <span>
                                <p>Klaim produk Anda, disetujui! <span className="status-garansi approved">Approved</span></p>
                                <p className="green note-garansi">Silahkan kirimkan ke alamat workshop kami dan sertakan identitas lengkap Anda.</p>
                            </span>
                            <span>
                                <p>Klaim produk Anda, ditolak! R <span className="status-garansi rejected">Rejected</span></p>
                                <p className="red note-garansi">Jika ada pertanyaan tentang produk Anda, silahkan hubungi Customer Service kami.</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    {data==='wishlist' &&
        // <div className="content-profile">
        //     {userWishlist.result.length>0 &&
        //         <div className="content-profile-list active">
        //             <div className="text-center mb2">
        //                 <div className="bold-caption">Wishlist</div>
        //             </div>
        //             <div className="wishlist-row">
        //                 <div className="template-container" ref={itemRef}>
        //                     {userWishlist.result.map(data=>{
        //                         return (
        //                             <div className="template-item" key={data.id}>
        //                                 <div className="template-card">
        //                                     {data.guaranteeId && <img src={garansiImg} className="garansi" alt='garansi'/>}
        //                                     {data.discountId && <img src={discountImg} className="sale" alt="sale" />}
        //                                     <div className="template-card-header">
        //                                         <img src={data.pathImage} alt="productImage" />
        //                                     </div>
        //                                     <div className="template-card-product">
        //                                         <div className="top-product-info">
        //                                             <div className="top-product-name">
        //                                                 <div className="brand">{data.brandName}</div>
        //                                                 <div className="name">{data.name}</div>
        //                                             </div>
        //                                         </div>
        //                                         <div className="bottom-product-info">
        //                                             <div className="bottom-product-price">Rp. {data.price}</div>
        //                                         </div>
        //                                         <div className="inline-space bottom-utility-wrapper">
        //                                             <button type="" className="btnNine btnNine-default" onClick={handleClickBuy}>Beli</button>
        //                                             <a className="btnNine btn-utils-product" onClick={handleClickCart}>
        //                                                 <svg className="wish-cart" viewBox="0 0 260.293 260.293">
        //                                                     <g>
        //                                                         <path d="M258.727,57.459c-1.42-1.837-3.612-2.913-5.934-2.913H62.004l-8.333-32.055c-0.859-3.306-3.843-5.613-7.259-5.613H7.5
        //                                                             c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h33.112l8.333,32.055c0,0.001,0,0.001,0.001,0.002l29.381,112.969
        //                                                             c0.859,3.305,3.843,5.612,7.258,5.612h137.822c3.415,0,6.399-2.307,7.258-5.612l29.385-112.971
        //                                                             C260.636,61.687,260.147,59.295,258.727,57.459z M117.877,167.517H91.385l-5.892-22.652h32.384V167.517z M117.877,129.864H81.592
        //                                                             l-5.895-22.667h42.18V129.864z M117.877,92.197H71.795l-5.891-22.651h51.973V92.197z M176.119,167.517h-43.242v-22.652h43.242
        //                                                             V167.517z M176.119,129.864h-43.242v-22.667h43.242V129.864z M176.119,92.197h-43.242V69.546h43.242V92.197z M217.609,167.517
        //                                                             h-26.49v-22.652h32.382L217.609,167.517z M227.403,129.864h-36.284v-22.667h42.18L227.403,129.864z M237.201,92.197h-46.081V69.546
        //                                                             h51.974L237.201,92.197z"/>
        //                                                         <path d="M105.482,188.62c-15.106,0-27.396,12.29-27.396,27.395c0,15.108,12.29,27.4,27.396,27.4
        //                                                             c15.105,0,27.395-12.292,27.395-27.4C132.877,200.91,120.588,188.62,105.482,188.62z M105.482,228.415
        //                                                             c-6.835,0-12.396-5.563-12.396-12.4c0-6.835,5.561-12.395,12.396-12.395c6.834,0,12.395,5.561,12.395,12.395
        //                                                             C117.877,222.853,112.317,228.415,105.482,228.415z"/>
        //                                                         <path d="M203.512,188.62c-15.104,0-27.392,12.29-27.392,27.395c0,15.108,12.288,27.4,27.392,27.4
        //                                                             c15.107,0,27.396-12.292,27.396-27.4C230.908,200.91,218.618,188.62,203.512,188.62z M203.512,228.415
        //                                                             c-6.833,0-12.392-5.563-12.392-12.4c0-6.835,5.559-12.395,12.392-12.395c6.836,0,12.396,5.561,12.396,12.395
        //                                                             C215.908,222.853,210.347,228.415,203.512,228.415z"/>
        //                                                     </g>
        //                                                 </svg>
        //                                             </a>
        //                                             <a className="btnNine btn-utils-product" onClick={handleClickDelete}>
        //                                                 <svg viewBox="0 0 1080 1080"><path d="M268,1080c-9.24-3.11-19-5.22-27.64-9.5-36.39-18-51.56-49-54.65-88.38-10.3-131.67-21.38-263.28-32.25-394.91-5.23-63.36-10.67-126.71-15.95-190.07-.32-3.83,0-7.71,0-12.36h806c-1.87,23.49-3.65,46.8-5.58,70.11Q929.9,550.44,921.89,646q-9.17,110.27-18.31,220.53c-3.55,42.71-6.32,85.5-10.93,128.09-4.81,44.4-32.93,74-76.78,83.68a19.45,19.45,0,0,0-3.73,1.69ZM381.87,700.73q0-109.58,0-219.17c0-17-6.82-27.47-20.37-32.08-22.9-7.8-42.84,7.13-42.85,32.35q-.08,201.78,0,403.57c0,12.64-.26,25.3.12,37.93.42,14.13,8.26,24.11,21.29,28.13,22.47,6.93,41.77-7.43,41.82-31.55Q382,810.31,381.87,700.73Zm189.83-.15q0-109.58,0-219.17c0-16.91-6.89-27.4-20.51-32-22.94-7.71-42.7,7.23-42.71,32.54q-.08,212.32,0,424.64c0,6-.24,12,.15,17.91.87,13.45,8.67,23.09,21.4,27,22.5,6.85,41.63-7.53,41.67-31.73Q571.83,810.16,571.69,700.58Zm126.55-.29q0,16.34,0,32.68,0,93.3,0,186.59c0,16.75,7.08,27.29,20.82,31.76,22.67,7.38,42.37-7.28,42.39-31.93q.14-147.59,0-295.17c0-47.44-.07-94.88,0-142.32,0-13.85-5.06-24.7-17.63-31.1-21.73-11.08-45.57,4.61-45.63,30.22Q698.08,590.66,698.25,700.29Z"/><path d="M542.2,316.48q-214.55,0-429.1-.1A135.81,135.81,0,0,1,87,314c-12.56-2.49-20.7-10.65-20.89-23.17-.42-28.41-2-57.18,1.6-85.18,6-46.19,45.69-78.61,92.57-78.9q88.56-.55,177.12.11c8.4.06,12.43-2.09,14.84-10.38,5-17.17,10.9-34.08,16.8-51C383.17,25,417.45.3,460.14.15q80.13-.29,160.25,0c41.64.17,76.12,24.59,90.2,63.78q9.6,26.74,17.88,53.95c2.11,7,5.37,9,12.61,9,57.63-.34,115.27-.22,172.9-.18,60.3,0,100.52,40.16,100.63,100.31q0,26.88,0,53.77c-.09,23.16-12.66,35.71-35.94,35.72Q760.44,316.51,542.2,316.48ZM415.39,126H664.73c-5-14.8-9.4-28.84-14.63-42.55-5-13.2-15.47-19.89-29.41-19.94q-80.64-.3-161.28,0c-14,.05-24.38,6.79-29.43,19.94C424.72,97.11,420.41,111.17,415.39,126Z"/></svg>
        //                                             </a>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         )
        //                     })}
        //                 </div>
        //                 <div className="pagination-cover">
        //                     <ul className="pagination">
        //                         {userWishlist.totalPage > 5 && queryParam.page > 1 &&
        //                             <li className="pagination-item item-prev">
        //                                 <a>
        //                                     <i className="fa fa-angle-left" aria-hidden="true" onClick={(e)=>onClickPagingPrevOrNext(e,'prev')}></i>
        //                                 </a>
        //                             </li>
        //                         }
        //                         {Array.from({length: userWishlist.totalPage}, (_,i)=>{
        //                             return (
        //                                 <li key={i+1} className={`pagination-item ${queryParam.page === (i+1) ? "active" : ""}`}>
        //                                     <a onClick={(e)=>onClickPaging(e,i+1)}>{i+1}</a>
        //                                 </li>
        //                             )
        //                         })}
        //                         {userWishlist.totalPage > 5 && userWishlist.totalPage - queryParam.page > 4 &&
        //                             <li className="pagination-item item-next">
        //                                 <a>
        //                                     <i className="fa fa-angle-right" aria-hidden="true" onClick={(e)=>onClickPagingPrevOrNext(e,'next')}></i>
        //                                 </a>
        //                             </li>
        //                         }
        //                     </ul>
        //                 </div>
        //             </div>
        //         </div>
        //     }
        //     {userWishlist.result.length === 0 &&
        //         <div className="content-profile-list active">
        //             <div className="notif-content-profile">
        //                 <div className="caption-notif">
        //                     <div className="notif-message">Produk tidak ditemukan.</div>
        //                     {/* <div className="notif-icon">
        //                         <svg viewBox="0 0 1080 1080"><path className="cls-1" d="M472.11,587c2.91,30.71,8,53.3,15.26,69.23,9.45,20.29,26.77,31,50.39,31,22.65,0,40.7-11.14,50.51-31.31,8.12-16.53,13.2-38.88,15.63-68.2L623.4,362a590.27,590.27,0,0,0,3.39-63.23c0-37.19-5-65.41-15.14-86.37-8.24-17-26.77-37.13-68.44-37.13-26.65,0-48.7,9-65.41,27-16.35,17.56-24.59,41.55-24.59,71.29q0,28.34,4.12,93.33Z"/><path className="cls-1" d="M539.58,732.4c-24,0-44.7,8.42-61.41,25.13s-25,37.13-25,60.75c0,27,9.08,48.45,26.89,64,17.2,14.9,37.43,22.41,60.32,22.41,22.65,0,42.76-7.63,60-22.83,17.56-15.69,26.41-37.07,26.41-63.53,0-23.8-8.6-44.27-25.68-60.93S563.44,732.4,539.58,732.4Z"/><path className="cls-1" d="M540,1080C242.24,1080,0,837.76,0,540S242.24,0,540,0s540,242.24,540,540S837.76,1080,540,1080ZM540,79.65C286.16,79.65,79.65,286.16,79.65,540S286.16,1000.35,540,1000.35,1000.35,793.84,1000.35,540,793.84,79.65,540,79.65Z"/></svg>
        //                         <div className="notif-hide">
        //                             Sistem tidak menyimpan data dan akses produk untuk melakukan garansi atau cek status garansi.
        //                         </div>
        //                     </div> */}
        //                 </div>
        //             </div>
        //         </div>
        //     }
        // </div>
        <UserWishlist onChange={onChange} setLoginClicked={setLoginClicked}/>
    }
    {data==='notifikasi' &&
        <div className="content-profile">
            <div className="content-profile-list active">
                <div className="text-center">
                    <div className="bold-caption">Notifikasi</div>
                </div>
                <div className="carousel-wrapper">
                    <div className={currentStateNotification==="orderStatus" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickNotification(e,'orderStatus')}>Status Pesanan</div>
                        <div className={currentStateNotification==="orderStatus" ? "carousel-list-body active" : "carousel-list-body"}>
                            {userNotificationOrders.length>0 && userNotificationOrders.map(data=>{
                                return (
                                    <div className="row-notification">
                                        <div className="foto-notif">
                                            <img src={data?.order?.order_items[0]?.product_color?.product_color_images[0].file.name} alt="productImage" />
                                        </div>
                                        <div className="desc-notif">
                                            <div className="caption-notif">{data.notificationMessage}</div>
                                            <div className="nopesanan-notif">{data.order.id} (Nomor pesanan)</div>
                                            <div className="time-notif">{convertDate(formatDateWithTime,data.order.createdAt)}</div>
                                        </div>
                                        <Link to={"/status-pesanan?orderId="+data.orderId}>
                                            <div className="btn-view-notif">Lihat Selengkapnya</div>
                                        </Link>
                                    </div>
                                )
                            })}
                            <div className="row-notification">
                                <div className="foto-notif">
                                    <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                </div>
                                <div className="desc-notif">
                                    <div className="caption-notif">Pesanan belum dibayar</div>
                                    <div className="nopesanan-notif">NN223108671251 (Nomor pesanan)</div>
                                    <div className="time-notif">06-10-2023 12.14</div>
                                </div>
                                <Link to="/status-belum-bayar">
                                    <div className="btn-view-notif">Lihat Selengkapnya</div>
                                </Link>
                            </div>
                            <div className="row-notification">
                                <div className="foto-notif">
                                    <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                </div>
                                <div className="desc-notif">
                                    <div className="caption-notif">Pesanan sedang diproses</div>
                                    <div className="nopesanan-notif">NN223108671251 (Nomor pesanan)</div>
                                    <div className="time-notif">06-10-2023 12.14</div>
                                </div>
                                <Link to="/status-diproses">
                                    <div className="btn-view-notif">Lihat Selengkapnya</div>
                                </Link>
                            </div>
                            <div className="row-notification">
                                <div className="foto-notif">
                                    <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                </div>
                                <div className="desc-notif">
                                    <div className="caption-notif">Pesanan sedang dikirim</div>
                                    <div className="nopesanan-notif">NN223108671251 (Nomor pesanan)</div>
                                    <div className="time-notif">06-10-2023 12.14</div>
                                </div>
                                <Link to="/status-dikirim">
                                    <div className="btn-view-notif">Lihat Selengkapnya</div>
                                </Link>
                            </div>
                            <div className="row-notification">
                                <div className="foto-notif">
                                    <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                </div>
                                <div className="desc-notif">
                                    <div className="caption-notif">Pesanan Selesai</div>
                                    <div className="nopesanan-notif">NN223108671251 (Nomor pesanan)</div>
                                    <div className="time-notif">06-10-2023 12.14</div>
                                </div>
                                <Link to="/status-selesai">
                                    <div className="btn-view-notif">Lihat Selengkapnya</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={currentStateNotification==="guarantee" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickNotification(e,'guarantee')}>Garansi</div>
                        <div className={currentStateNotification==="guarantee" ? "carousel-list-body active" : "carousel-list-body"}>
                            <p>Klaim produk Anda sedang dalam proses pemeriksaan, mohon ditunggu!</p>
                            <span>
                                <p>Klaim produk Anda, disetujui! <span className="status-garansi approved">Approved</span></p>
                                <p className="green note-garansi">Silahkan kirimkan ke alamat workshop kami dan sertakan identitas lengkap Anda.</p>
                            </span>
                            <span>
                                <p>Klaim produk Anda, ditolak! R <span className="status-garansi rejected">Rejected</span></p>
                                <p className="red note-garansi">Jika ada pertanyaan tentang produk Anda, silahkan hubungi Customer Service kami.</p>
                            </span>
                        </div>
                    </div>
                    <div className={currentStateNotification==="news" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickNotification(e,'news')}>Berita</div>
                        <div className={currentStateNotification==="news" ? "carousel-list-body active" : "carousel-list-body"}>
                            <div className="gray2 notif-text">GJAW 2023, OPEN TODAY!</div>
                            <div className="green notif-text">IIMS 2024, OPEN TODAY! HALL B2 D11</div>
                            <div className="gray2 notif-text">PRODUK LAMPU ZAMAN NOW, COBAIN!</div>
                        </div>
                    </div>
                    <div className={currentStateNotification==="promo" ? "carousel-list active" : "carousel-list"}>
                        <div className="carousel-list-header js-carousel-list" onClick={(e)=>handleClickNotification(e,'promo')}>Promo</div>
                        <div className={currentStateNotification==="promo" ? "carousel-list-body active" : "carousel-list-body"}>
                            <div className="gray2 notif-text">LUXIMOS DHB - 1, PROMO! 50% OFF</div>
                            <div className="green notif-text">HORN K80, PROMO! 80% OFF</div>
                            <div className="gray2 notif-text">JANGAN SAMPAI KEHABISAN! PRODUK LUXIMOS GARANSI 1 TAHUN</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
  )
}

export default UserContent