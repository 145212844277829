import React, { useEffect, useRef, useState } from 'react'
import { makeRequest } from '../axios';
import { Link,useNavigate } from 'react-router-dom';

const TopMenu = ({menu,onUpdateMenu}) => {
    const navigate = useNavigate();
    const [usermenus,setUsermenus] = useState([]);
    const [productSelected,setProductSelected] = useState(false);
    const [downloadSelected,setDownloadSelected] = useState(false);
    const [categories,setCategories] = useState([]);
    const mainCategoryRef = useRef();
    const productRef = useRef(null);
    const downloadRef = useRef(null);

    const initUsermenus = async () => {
        try {
            const result = await makeRequest.get("menus/find-by-role-name?roleName=user");
            setUsermenus(result.data.data);
        } catch (error) {
            console.log(error);
            setUsermenus([]);
        }
    }
    const initCategories = async () => {
        try {
            const result = await makeRequest.get("categories/find-all");
            setCategories(result.data.data);
        } catch (error) {
            console.log(error);
            setCategories([]);
        }
    }
    const updateMenu = (e,value) => {
        e.preventDefault();
        if(value === 'produk'){
            setProductSelected(true);
        } else if(value === 'unduh'){
            setDownloadSelected(true);
        } else {
            setProductSelected(false);
            setDownloadSelected(false);
        }
        onUpdateMenu(e,value);
    }
    const handleClickOutsideProduct = (e) => {
        e.preventDefault();
        if (productRef.current && !productRef.current.contains(e.target)) {
            setProductSelected(false);
        }
    };
    const handleClickOutsideDownload = event => {
        if (downloadRef.current && !downloadRef.current.contains(event.target)) {
            setDownloadSelected(false);
        }
    };
    const getSubCategoriesByCategory = async(categoryName) => {
        try {
            const response = await makeRequest.get("sub-categories/find-by-category-name?categoryName="+categoryName);
            const result = response.data.data.map(category=>category.name);
            return result;
        } catch (error) {
            return [];
        }
    }
    const clickSubCategory = async(e,value) => {
        e.preventDefault();
        setProductSelected(false);
        const subCategories = await getSubCategoriesByCategory(value);
        navigate("/produk?kategori="+value+"&halaman=1&urutkan=produk_terlaris&subKategori="+subCategories);
        window.location.reload();
    }
    const clickSubDownload = (e) => {
        e.preventDefault();
        setDownloadSelected(false);
    }
    useEffect(()=>{
        initUsermenus();
        initCategories();
        if(menu==='produk'){
            document.addEventListener("mousedown", handleClickOutsideProduct);
        }
        if(menu==='unduh'){
            document.addEventListener("mousedown", handleClickOutsideDownload);
        }

        return () => {
            if(menu==='produk'){
                document.removeEventListener("mousedown", handleClickOutsideProduct);
            }
            if(menu==='unduh'){
                document.removeEventListener("mousedown", handleClickOutsideDownload);
            }
        };
    },[])
  return (
    <ul className="menu-top">
        {usermenus.length > 0 && usermenus.map(usermenu =>{
            return (
                <div key={usermenu.id}>
                    {usermenu.name==='Beranda' &&
                        <li onClick={(e)=> updateMenu(e,'beranda')}>
                            <Link to={usermenu.path} role="button" className={menu === 'beranda' ? 'active':''}>
                                {usermenu.name}
                            </Link>
                        </li>
                    }
                    {usermenu.name==='Produk' &&
                        <li id="produk" ref={productRef} className={productSelected ? "has-sub selected" : "has-sub"}>
                            <a className={menu === 'produk'? 'active':''} onClick={(e)=> updateMenu(e,'produk')}>Produk</a>
                            <div className="dropdown-list-wrapper" style={{display:productSelected ? 'block' : 'none'}}>
                            {/* <div className="dropdown-list-wrapper" ref={mainCategoryRef}> */}
                                <ul className="two-column">
                                    {categories.length > 0 && categories.map(category=>{
                                        return (
                                            <li key={category.id} onClick={(e)=>clickSubCategory(e,category.name)}>
                                                <a role="button">{category.name}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </li>
                    }
                    {usermenu.name==='Berita' &&
                        <li onClick={(e)=> updateMenu(e,'berita')}>
                            <Link to={usermenu.path} role="button" className={menu === 'berita' ? 'active':''}>
                                Berita
                            </Link>
                        </li>
                    }
                    {usermenu.name==='Garansi' &&
                        <li onClick={(e)=> updateMenu(e,'garansi')}>
                            <Link to={usermenu.path} role="button" className={menu === 'garansi' ? 'active':''}>
                                Garansi
                            </Link>
                        </li>
                    }
                    {usermenu.name==='Unduh' &&
                        <li ref={downloadRef} className={downloadSelected ? "has-sub selected" : "has-sub"}>
                            <a className={menu === 'unduh'? 'active':''} onClick={(e)=> updateMenu(e,'unduh')}>Unduh</a>
                            <div className="dropdown-list-wrapper" style={{display:downloadSelected ? 'block' : 'none'}}>
                                <ul>
                                    <li style={{width:'100px'}} onClick={clickSubDownload}>
                                        <Link to="/brosur">
                                            <span>Brosur</span>
                                        </Link>
                                    </li>
                                    <li style={{width:'100px'}} onClick={clickSubDownload}>
                                        <Link to="/katalog">
                                            <span>Katalog</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    }
                </div>
            )
        })}
    </ul>
  )
}

export default TopMenu